import {
  CLEAR_GRANTS,
  CLEAR_SERVICE_FOCUS,
  CLEAR_TOTAL_GRANTS,
  GET_GRANTS,
  GET_SERVICE_FOCUS,
  GET_TOTAL_GRANTS,
  REMOVE_SAVED_GRANT,
  TOGGLE_SAVED_GRANT,
} from '../../actions/grantAction';

const initialState = {
  serviceFocusOptions: {},
  allGrants: {
    total: 0,
    grants: [],
  },
  // Total amount of grants money-wise
  grantsTotal: 0,
  lastUpdatedDate: new Date(),
};

const GrantReducer = (state = initialState, action) => {
  let updatedGrants = [...state.allGrants.grants];
  switch (action.type) {
    case GET_GRANTS:
      return {
        ...state,
        allGrants: {
          total: action.grantsData.total,
          grants: action.grantsData.grants,
          lastUpdatedDate: action.grantsData.last_updated_date || new Date(),
        },
      };
    case TOGGLE_SAVED_GRANT:
      // Find updated grant and replace with response grant
      updatedGrants = updatedGrants.map(grant => {
        if (grant.id === action.grant.id) {
          return action.grant;
        }
        return grant;
      });
      return { ...state, allGrants: { ...state.allGrants, grants: updatedGrants } };
    case REMOVE_SAVED_GRANT:
      // Find updated grant and remove it
      updatedGrants = updatedGrants.filter(grant => grant.id !== action.grant.id);
      return { ...state, allGrants: { ...state.allGrants, grants: updatedGrants } };
    case GET_SERVICE_FOCUS:
      return { ...state, serviceFocusOptions: action.serviceFocusOptions };
    case GET_TOTAL_GRANTS:
      return { ...state, grantsTotal: action.total };
    case CLEAR_GRANTS:
      return { ...state, allGrants: { total: 0, grants: [] } };
    case CLEAR_SERVICE_FOCUS:
      return { ...state, serviceFocusOptions: {} };
    case CLEAR_TOTAL_GRANTS:
      return { ...state, grantsTotal: 0 };
    default:
      return state;
  }
};

export default GrantReducer;
