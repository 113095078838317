import api from '../../../utils/Api';

export const GET_ALL_TEAMS = 'teams/GET_ALL';
export const GET_TEAMS = 'teams/GET';
export const GET_MEMBERS_BY_TEAM = 'teams/GET_MEMBERS_BY_TEAM';
export const CLEAR_ALL_TEAMS = 'teams/CLEAR_ALL';
export const CLEAR_TEAMS = 'teams/CLEAR';
export const CLEAR_SELECTED_TEAM = 'teams/CLEAR_SELECTED_TEAM';
export const CLEAR_MEMBERS_BY_TEAM = 'teams/CLEAR_MEMBERS_BY_TEAM';
export const GET_SELECTED_TEAM = 'teams/getSelectedTeam';

export const getAllTeamsAction = teamsData => {
  return {
    type: GET_ALL_TEAMS,
    teamsData,
  };
};

export const getTeamsAction = teamsData => {
  return {
    type: GET_TEAMS,
    teamsData,
  };
};

export const getMembersByTeamAction = teamMembersData => {
  return {
    type: GET_MEMBERS_BY_TEAM,
    teamMembersData,
  };
};

export const clearAllTeamsAction = () => {
  return {
    type: CLEAR_ALL_TEAMS,
  };
};

export const clearTeamsAction = () => {
  return {
    type: CLEAR_TEAMS,
  };
};

export const clearSelectedTeamAction = () => {
  return {
    type: CLEAR_SELECTED_TEAM,
  };
};

export const clearMembersByTeamAction = teamPk => {
  return {
    type: CLEAR_MEMBERS_BY_TEAM,
    teamPk,
  };
};

export const selectedTeamAction = team => ({
  type: GET_SELECTED_TEAM,
  team,
});

export const getAllTeamsThunk = params => async dispatch => {
  try {
    const response = await api.get('organization/teams/', params);
    if (response && response.ok) {
      dispatch(getAllTeamsAction(response.data));
    }
    return response;
  } catch (error) {
    throw error;
  }
};

export const getTeamsThunk = params => async dispatch => {
  try {
    const response = await api.get('organization/teams/', params);
    // If view all teams, add it to all teams store
    if (params && params.view_all_teams) dispatch(getAllTeamsAction(response.data));
    // Else add it to just the store for the user's teams
    else dispatch(getTeamsAction(response.data));
    return response;
  } catch (error) {
    throw error;
  }
};

export const getMembersByTeamThunk = params => async dispatch => {
  try {
    const response = await api.get('/member/', params);
    dispatch(getMembersByTeamAction({ teamPk: params.project, members: response.data.members }));
  } catch (error) {
    throw new Error(error.data.error || 'An error occurred while fetching team members.');
  }
};

export const createEditTeamsThunk = (teamsData, currentPage, rowsPerPage) => async dispatch => {
  try {
    const response = await api.post('organization/teams/', teamsData);
    dispatch(getTeamsThunk({ page: currentPage, per_page: rowsPerPage }));
    dispatch(getAllTeamsThunk());
    return response;
  } catch (error) {
    throw error;
  }
};
