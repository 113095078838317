import { GET_ORG_MEMBERS, GET_SELECTED_MEMBER, CLEAR_SELECTED_MEMBER, EDIT_MEMBER } from '../../actions/memberAction';

const initialState = {
  data: [],
  total: 0,
  params: {},
  selectedMember: {},
};

const members = (state = initialState, action) => {
  switch (action.type) {
    case GET_ORG_MEMBERS:
      let { data, totalPages, params } = action.payload;
      if (params['status'] === 'pending') {
        data = data.map(d => ({
          ...d,
          user: { email: d.email, name: d.email.split('@')[0] },
          status: 'Pending',
        }));
      }
      return {
        ...state,
        data,
        total: totalPages,
        params,
      };
    case GET_SELECTED_MEMBER:
      return { ...state, selectedMember: action.member };
    case EDIT_MEMBER:
      const updatedMembers = state.data.map(member => (member.pk === action.member.pk ? action.member : member));
      return { ...state, data: updatedMembers };
    case CLEAR_SELECTED_MEMBER:
      return { ...state, selectedMember: {} };
    default:
      return state;
  }
};

export default members;
