import React, { createContext, useState, useCallback, useEffect } from 'react';

const ModalContext = createContext({
  modalOpen: false,
  activeModalContent: null,
  toggleModal: () => {},
  setActiveModalContent: () => {},
  resetForm: () => {},
  needsConfirmationToClose: false,
  setNeedsConfirmationToClose: () => {},
  alert: '',
  setAlert: () => {},
});

export const ModalProvider = ({ children }) => {
  const [modalTitle, setModalTitle] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [activeModalContent, setActiveModalContent] = useState(null);
  const [resetForm, setResetForm] = useState(() => () => {});
  const [needsConfirmationToClose, setNeedsConfirmationToClose] = useState(false);
  const [teamRoleUpdated, setTeamRoleUpdated] = useState(false);
  const [alert, setAlert] = useState('');

  const toggleModal = useCallback(() => {
    if (modalOpen && needsConfirmationToClose) {
      setAlert('Do you want to close this modal, you still have unsaved changes.');
    } else {
      setModalOpen(prev => !prev);
      setAlert('');
    }
  }, [modalOpen, needsConfirmationToClose]);

  useEffect(() => {
    if (!modalOpen && resetForm) {
      resetForm();
      setNeedsConfirmationToClose(false);
      setAlert('');
    }
  }, [modalOpen, resetForm, needsConfirmationToClose]);

  return (
    <ModalContext.Provider
      value={{
        modalOpen,
        activeModalContent,
        toggleModal,
        setActiveModalContent,
        modalTitle,
        setModalTitle,
        setResetForm,
        needsConfirmationToClose,
        setNeedsConfirmationToClose,
        alert,
        setAlert,
        teamRoleUpdated,
        setTeamRoleUpdated,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};

export default ModalContext;
