import {
  GET_ORG_PROFILE,
  CREATE_ORG_PROFILE,
  CLEAR_ORG_PROFILE,
  EDIT_ORG_PROFILE,
  GET_ORG_REPRESENTATIVES,
  DELETE_ORG_REPRESENTATIVE,
} from '../../actions/organizationProfileAction';

const initialState = { representatives: [] };

const organizationProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ORG_PROFILE:
    case CREATE_ORG_PROFILE:
    case EDIT_ORG_PROFILE:
      return { ...state, ...action.organizationProfile };
    case GET_ORG_REPRESENTATIVES:
      return { ...state, representatives: action.representatives };
    case DELETE_ORG_REPRESENTATIVE:
      return {
        ...state,
        representatives: state.representatives.filter(rep => rep.pk !== action.id),
      };
    case CLEAR_ORG_PROFILE:
      return { representatives: [] };
    default:
      return state;
  }
};

export default organizationProfileReducer;
